import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Toolbar,
} from '@mui/material';
import Image from 'next/image';
import { useWindowDimensions } from '../../hooks';
import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '../Link/AppLink';
import AppLink from '../Link/AppLink';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { CloudMegaMenu } from './CloudMegaMenu/CloudMegaMenu';
import { AcnCloudIcon } from '../Content/icons/AcnCloudIcon';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import { useRouter } from 'next/router';
import { CUSTOMER_SERVICE_ROUTE } from '../../pages/routes';
import FlagIcon from '@mui/icons-material/Flag';

interface HeaderProps {
  logo?: string;
}

export function Header(props: HeaderProps) {
  const { logo } = props;
  const router = useRouter();
  const { isMobile } = useWindowDimensions();

  const [megaMenuVisible, setMegaMenuVisible] = useState<boolean>(false);

  const navigate = (path: string) => {
    setMegaMenuVisible(false);
    router.push(path);
  };

  return (
    <>
      <AppBar color="primary" sx={{ backgroundImage: 'none' }}>
        <Container disableGutters maxWidth="xl">
          <Toolbar sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flexGrow: 1, mt: 1 }}>
              <Link href={'/'}>
                <Image
                  src={
                    logo ||
                    'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/acn_logo_white.svg'
                  }
                  width={250}
                  height={50}
                  alt="logo"
                />
              </Link>
            </Box>
            <Box>
              {isMobile ? (
                <IconButton
                  onClick={() => setMegaMenuVisible(!megaMenuVisible)}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <Stack direction={'row'} spacing={2}>
                  <Button
                    startIcon={<FlagIcon />}
                    sx={{ px: 1.5 }}
                    color="inherit"
                    onClick={() => navigate('/')}
                  >
                    Start
                  </Button>
                  <Button
                    startIcon={<AcnCloudIcon />}
                    sx={{ '.MuiSvgIcon-root': { fontSize: 25 }, px: 1.5 }}
                    onClick={() => setMegaMenuVisible(!megaMenuVisible)}
                    color="inherit"
                  >
                    Producten
                  </Button>
                  <Button
                    onClick={() => navigate(CUSTOMER_SERVICE_ROUTE)}
                    color="inherit"
                    sx={{ px: 1.5 }}
                    startIcon={<SupportAgentIcon />}
                  >
                    Klantenservice
                  </Button>
                  <Button
                    component={AppLink}
                    href={'tel:+31850604011'}
                    variant={'outlined'}
                    color="primary"
                    startIcon={<CallIcon />}
                  >
                    Bel ons
                  </Button>
                  <Button
                    component={AppLink}
                    target={'_blank'}
                    href={'https://wa.me/31850604011'}
                    variant={'contained'}
                    color="primary"
                    startIcon={<WhatsAppIcon />}
                  >
                    WhatsApp ons
                  </Button>
                </Stack>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box>
        <CloudMegaMenu
          visible={megaMenuVisible}
          onClose={() => setMegaMenuVisible(false)}
        />
      </Box>
    </>
  );
}
