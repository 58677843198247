import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import AppLink from '../Link/AppLink';
import Image from 'next/image';
import React from 'react';
import {
  ACN_CLOUD_ROUTE,
  COMMUNIQATE_ROUTE,
  CUSTOMER_SERVICE_ROUTE,
  KENNISBANK_ROUTE,
  MARQTING_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  VERWERKERS_OVEREENKOMST_ROUTE,
} from '../../pages/routes';
import { HighLightedText } from '../HighLightedText/HighLightedText';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { AcnCloudIcon } from '../Content/icons/AcnCloudIcon';
import { MarqtingIcon } from '../Content/icons/MarqtingIcon';
import { CommuniqateIcon } from '../Content/icons/CommuniqateIcon';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GavelIcon from '@mui/icons-material/Gavel';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArticleIcon from '@mui/icons-material/Article';
import CookieIcon from '@mui/icons-material/Cookie';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import BusinessIcon from '@mui/icons-material/Business';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

interface NavigationItem {
  label: string;
  items: {
    label: string;
    href?: string;
    external?: boolean;
    icon?: React.ReactNode;
  }[];
}

const navigationItems: NavigationItem[] = [
  {
    label: 'Producten',
    items: [
      {
        icon: <CommuniqateIcon />,
        label: 'CommuniQate',
        href: COMMUNIQATE_ROUTE,
      },
      {
        icon: <MarqtingIcon sx={{ fontSize: '1.4rem' }} />,
        label: 'MarQting',
        href: MARQTING_ROUTE,
      },
      {
        icon: <AcnCloudIcon />,
        label: 'ACN Cloud',
        href: ACN_CLOUD_ROUTE,
      },
    ],
  },
  {
    label: 'Support',
    items: [
      {
        icon: <SupportAgentIcon />,
        label: 'Klantenservice',
        href: CUSTOMER_SERVICE_ROUTE,
      },
      {
        icon: <LibraryBooksIcon />,
        label: 'Kennisbank',
        href: KENNISBANK_ROUTE,
      },
    ],
  },
  {
    label: 'Informatie',
    items: [
      {
        icon: <GavelIcon />,
        label: 'Algemene voorwaarden',
        href: TERMS_OF_SERVICE_ROUTE,
      },
      {
        icon: <AdminPanelSettingsIcon />,
        label: 'Privacy voorwaarden',
        href: PRIVACY_POLICY_ROUTE,
      },
      {
        icon: <ArticleIcon />,
        label: 'Verwerkersovereenkomst',
        href: VERWERKERS_OVEREENKOMST_ROUTE,
      },
      {
        icon: <CookieIcon />,
        label: 'Cookies',
        href: PRIVACY_POLICY_ROUTE,
      },
    ],
  },
  {
    label: 'Organisatie',
    items: [
      {
        icon: <LocationOnIcon />,
        label: 'Marssteden 106, 7547TD ENSCHEDE',
        href: 'https://www.google.com/maps/place/AppCenter+Nederland+%7C+WhatsApp+Business+Automatisering+en+meer./@52.1966174,6.8318364,3a,88.7y,80.53h,98.2t/data=!3m6!1e1!3m4!1spWju91S1OTx4ZyF4XpoUuQ!2e0!7i16384!8i8192!4m7!3m6!1s0x47b8159f82dc900d:0xa9b9517175ec6290!8m2!3d52.1966722!4d6.8323653!10e5!16s%2Fg%2F11jdd25twn?entry=ttu',
        external: true,
      },
      {
        icon: <BusinessIcon />,
        label: 'KVK 77687132',
        href: 'https://www.kvk.nl/zoeken/?source=all&q=AppCenter%20Nederland%20B.V.&start=0&site=kvk2014',
        external: true,
      },
      {
        icon: <ReceiptLongIcon />,
        label: 'BTW NL861097749B01',
      },
    ],
  },
];

function NavigationList({ item }: { item: NavigationItem }) {
  return (
    <Box>
      <HighLightedText
        TypographyProps={{ color: 'secondary' }}
        text={item.label}
      />

      <List>
        {item.items.map((item, key) => (
          <ListItem
            sx={{
              py: 1,
              justifyContent: { xs: 'center', lg: 'flex-start' },
              textAlign: { xs: 'center', lg: 'unset' },
            }}
            disablePadding
            key={key}
          >
            {item.icon && (
              <ListItemIcon sx={{ minWidth: 30 }}>{item.icon}</ListItemIcon>
            )}
            {item.href ? (
              <AppLink
                href={item.href}
                target={item.external ? '_blank' : '_self'}
                color="inherit"
                underline="hover"
              >
                {item.label}
              </AppLink>
            ) : (
              <Typography fontSize={'1rem'} color="inherit">
                {item.label}
              </Typography>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

function Navigation() {
  return (
    <Grid container spacing={4} sx={{ mt: -4, mb: 4 }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        sx={{ textAlign: { xs: 'center', lg: 'unset' } }}
      >
        <AppLink href={'/'}>
          <Image
            src={
              'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/acn_logo_white.svg'
            }
            width={250}
            height={40}
            alt="logo"
          />
        </AppLink>
        <Box mt={2} />
        <Typography sx={{ textAlign: { xs: 'center', lg: 'unset' } }}>
          Onze slimme producten op ons krachtige platform zorgen voor
          persoonlijke benadering en schaalbare groei.
        </Typography>
        <Box mt={2} />
        <Button
          size={'large'}
          href={'https://www.linkedin.com/company/appcenternederland'}
          target={'_blank'}
          variant={'text'}
          startIcon={<LinkedInIcon />}
          color={'primary'}
        >
          Volg ons op LinkedIn
        </Button>
      </Grid>
      {navigationItems.map((navigationItem, key) => (
        <Grid
          key={key}
          item
          xs={12}
          sm={6}
          md={2}
          lg={2}
          xl={2}
          sx={{ mt: 1, textAlign: { xs: 'center', lg: 'unset' } }}
        >
          <NavigationList item={navigationItem} />
        </Grid>
      ))}
    </Grid>
  );
}

export { Navigation };
